import moment from "moment";

import { isProduction } from "shared/api/instance";

export const packsLabels = isProduction
  ? [
    { value: "pay_per_race_1", label: "1 pack" },
    { value: "pay_per_race_2", label: "10 pack" },
    { value: "pay_per_race_3", label: "30 pack" },
    { value: "pay_per_race_4", label: "50 pack" },
  ]
  : [
    { value: "pay_per_race_staging_1", label: "1 pack" },
    { value: "pay_per_race_staging_2", label: "10 pack" },
    { value: "pay_per_race_staging_3", label: "30 pack" },
    { value: "pay_per_race_staging_4", label: "50 pack" },
  ];

export const getPacksText = (promotionPacks: string[]): string => {  
  return promotionPacks
    .map(pack => {
      const packLabel = packsLabels.find(label => label.value === pack);
      return packLabel ? packLabel.label : "";
    })
    .filter(label => label !== "")
    .join(", ");
};

export const formatDateToAustralian = (date: string) => {
  return moment(date).tz("Australia/Sydney").format("DD/MM/YYYY h:mm A");
};

export const formatToDateTimeLocal = (date:string) => 
  moment.tz(date, "Australia/Sydney").format("YYYY-MM-DDTHH:mm");
