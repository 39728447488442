import React from "react";

export const Advertising: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.59862 1.88599L5.69741 10.2066L2.02015 13.8839C1.40995 14.4941 1.40995 15.4834 2.02015 16.0936L3.12503 17.1985C3.73522 17.8087 4.72456 17.8087 5.33476 17.1985L9.01202 13.5212L17.3326 9.62001" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M18.4375 10.7249L8.49377 0.78125" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M16.8472 4.71527L18.4374 3.90625" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M14.5034 2.37152L15.3124 0.78125" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M6.8025 15.7309L9.81649 18.7448C10.1004 19.0371 10.4977 19.2188 10.9375 19.2188C11.8004 19.2188 12.5 18.5192 12.5 17.6563C12.5 17.2166 12.3183 16.8193 12.026 16.5354L5.69739 10.2068" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  
);

