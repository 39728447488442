import styled from "styled-components";

const FieldWrap = styled.div<{ error: boolean }>`
  border-color: ${({ error, theme }) =>
    error ? theme.colors.error : theme.colors.input};
`;

export const ErrorWrap = styled.div`
  margin: 6px 0;
  height: 17px;
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 14px;
  display: block;
  text-align: right;
  margin-right: 5px;
`;

type Props = {
  error?: string;
};

export const FormField: React.FC<Props> = ({ error, children }) => {
  return (
    <FieldWrap error={!!error}>
      {children}
      <ErrorWrap>
        <Error>{error}</Error>
      </ErrorWrap>
    </FieldWrap>
  );
};
