import { useStore } from "effector-react";

import { Typography } from "shared/ui/Typography";

import { ModalButton } from "features/user/createUser/ui/CreateUserModal";

import { CreatePromotionForm } from "./CreatePromotionForm";
import { Modal } from "./Modal";
import { $isCreatePromotionModalVisible, $isSuccessMessageVisible, cancelPromotionCreation } from "./model";

export const CreatePromotionModal: React.FC = () => {
  const isOpen = useStore($isCreatePromotionModalVisible);
  const isSuccess = useStore($isSuccessMessageVisible);

  const handleCloseModal = () => cancelPromotionCreation();

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      {isSuccess ? (
        <>
          <Typography variant="bold20">
           {isSuccess}
          </Typography>
          <ModalButton onClick={handleCloseModal}>Continue</ModalButton>
        </>
      ) : (
        <CreatePromotionForm />
      )}
    </Modal>
  );
};
