import React, { useCallback, useEffect, useState } from "react";
import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { calculatePlan } from "shared/lib/calculatePlan";
import { getFullName } from "shared/lib/getFullName";
import { Paging } from "shared/paging";
import { Customer, Plan } from "shared/types/customer";
import { GreenText } from "shared/ui/GreenText";
import { UserOptionsIcon } from "shared/ui/icons/UserOptions";
import { Loader } from "shared/ui/Loader";
import { PageLayout } from "shared/ui/PageLayout";
import { Popover, PopoverOption } from "shared/ui/popover";
import { Search } from "shared/ui/Search";
import {
  EmptyTablePlaceholder,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "shared/ui/table";
import { Typography } from "shared/ui/Typography";
import { Underlined } from "shared/ui/Underlined";
import { YellowText } from "shared/ui/YellowText";

import { CustomerInfo, customerInfoModel } from "features/customerInfo";
import { customerSubscribeModel } from "features/customerSubscribe";
import { setActiveCreditsRecipient } from "features/giveCredits";
import { GiveCreditsModal } from "features/giveCredits/ui/GiveCreditsModal";

import { DeleteCustomerModal } from "./DeleteCustomerModal";
import {
  $customersTotal,
  $data,
  $isDeleteCustomerModalVisible,
  $isEmpty,
  $isLoading,
  changePageSize,
  CustomersGate,
  loadNext,
  loadPage,
  loadPrev,
  loadTotalsFx,
  markCustomerForDeletion,
  searchText,
  update,
} from "./model";

const Amount = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
`;

const Email = styled(Typography)`
  text-decoration-line: underline;
  width: 70%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CustomerOptionsButton = styled.button`
  padding: 10px;
  vertical-align: middle;
  display: inline-flex;
`;

const SearchField = styled.div`
  margin-left: 40px;
  border: 1px solid white;
  width: 310px;
  height: 40px;
  border-radius: 50px;
  display: flex;
`;

export const CustomersPage: React.FC = () => {
  useGate(CustomersGate);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>(
    0 as unknown as NodeJS.Timeout
  );
  useEffect(() => () => clearTimeout(timeoutId), []);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const handleClosePopover = useCallback(() => setCustomerId(null), []);
  const handleTogglePopover = useCallback(
    (customer: Customer) =>
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setCustomerId((value) => (value ? null : customer.id));
      },
    []
  );
  const customerAction = useCallback(
    (action: (id: string) => void, id: string) => () => {
      action(id);
      setTimeoutId(setTimeout(update, 100));
      setCustomerId(null);
    },
    []
  );
  const updateUserBalance = () => {
    setTimeoutId(setTimeout(update, 100));
  };

  const { data, ...paging } = useStore($data);  
  const isEmpty = useStore($isEmpty);
  const isLoading = useStore($isLoading);
  const isVisibleDeleteModal = useStore($isDeleteCustomerModalVisible);
  const totalCustomers = useStore($customersTotal);
  const activeCustomerId = useStore(customerInfoModel.$customerId);
  const isCustomerInfoVisible = useStore(
    customerInfoModel.$isCustomerInfoVisible
  );
  const loading = useStore(loadTotalsFx.pending);
  const selectCustomer = (customerId: string) =>
    customerInfoModel.showCustomerInfo(customerId);
  if (loading) {
    return <Loader sideWidth="238px" />;
  }
  return (
    <>
      <PageLayout
        titleComponent={
          <Typography variant="bold20">
            <Underlined>Customers</Underlined>
          </Typography>
        }
        amountComponent={
          <Amount variant="bold20">
            Total amount of Customers: {totalCustomers}
          </Amount>
        }
      >
        <SearchField>
          <Search value={paging.searchText} onSearch={(value)=>searchText(value)} />
        </SearchField>
        {isLoading && !isVisibleDeleteModal && <Loader />}
        <Table striped>
          <TableHead>
            <TableRow>
              <TableHeadCell>Username</TableHeadCell>
              <TableHeadCell>Email</TableHeadCell>
              {!isCustomerInfoVisible && (
                <>
                  <TableHeadCell>Subscription</TableHeadCell>
                  <TableHeadCell>Credits</TableHeadCell>
                  <TableHeadCell>Total spent</TableHeadCell>
                  <TableHeadCell />
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmpty && (
              <EmptyTablePlaceholder>
                You have no Customers yet
              </EmptyTablePlaceholder>
            )}
            {data.map((customer) => (
              <TableRow
                key={customer.id}
                onClick={() => selectCustomer(customer.id)}
                clickable
                isActive={customer.id === activeCustomerId}
              >
                <TableCell>{getFullName(customer)}</TableCell>
                <TableCell>
                  <Email variant="regular16">{customer.email}</Email>
                </TableCell>
                {!isCustomerInfoVisible && (
                  <>
                    <TableCell>
                      {calculatePlan(customer) === Plan.Free ? (
                        <YellowText>{calculatePlan(customer)}</YellowText>
                      ) : (
                        <GreenText>{calculatePlan(customer)}</GreenText>
                      )}
                    </TableCell>
                    <TableCell>
                      <YellowText>
                        {calculatePlan(customer) === Plan.Free
                          ? customer.ticketsBalance
                          : "N/A"}
                      </YellowText>
                    </TableCell>
                    <TableCell>${customer.paymentTotal.amount}</TableCell>
                    <TableCell align="right">
                      <Popover
                        isOpen={customerId === customer.id}
                        onClose={handleClosePopover}
                        content={
                          <>
                            {customer.isActive ? (
                              <PopoverOption
                                onClick={customerAction(
                                  customerSubscribeModel.deactivateCustomerFx,
                                  customer.id
                                )}
                              >
                                Deactivate
                              </PopoverOption>
                            ) : (
                              <PopoverOption
                                onClick={customerAction(
                                  customerSubscribeModel.activateCustomerFx,
                                  customer.id
                                )}
                              >
                                Activate
                              </PopoverOption>
                            )}
                            <PopoverOption
                              onClick={() =>
                                setActiveCreditsRecipient(customer)
                              }
                            >
                              Give credits
                            </PopoverOption>
                            <PopoverOption
                              onClick={() =>{
                                markCustomerForDeletion({
                                  id: customer.id || "",
                                  firstName: customer.firstName || "",
                                  lastName: customer.lastName || "",
                                });
                                handleClosePopover();
                              }
                              }
                            >
                              Delete user
                            </PopoverOption>
                          </>
                        }
                      >
                        <CustomerOptionsButton
                          onClick={handleTogglePopover(customer)}
                        >
                          <UserOptionsIcon />
                        </CustomerOptionsButton>
                      </Popover>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Paging
          {...paging}
          loadNext={loadNext}
          loadPrev={loadPrev}
          loadPage={loadPage}
          changePageSize={changePageSize}
        />
        <DeleteCustomerModal />
      </PageLayout>
      <GiveCreditsModal onClose={updateUserBalance} />
      {isCustomerInfoVisible && <CustomerInfo />}
    </>
  );
};
