import { useCallback, useState } from "react";
import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { Promotion } from "shared/types/promotions";
import { Burger } from "shared/ui/icons/Burger";
import { UserOptionsIcon } from "shared/ui/icons/UserOptions";
import { Loader } from "shared/ui/Loader";
import { PageLayout } from "shared/ui/PageLayout";
import { Popover, PopoverOption } from "shared/ui/popover";
import {
  EmptyTablePlaceholder,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "shared/ui/table";
import { Typography } from "shared/ui/Typography";
import { Underlined } from "shared/ui/Underlined";

import { CreatePromotionModal } from "./CreatePromotionModal";
import { formatDateToAustralian, getPacksText } from "./lib";
import {
  $promotions,
  createPromotion,
  deletePromotionFx,
  editPromotionFx,
  PromotionsGate,
} from "./model";

const PromotionOptionsButton = styled.button`
  padding: 10px;
  vertical-align: middle;
  display: inline-flex;
`;

type CellProps = {
  width: number;
  color?: string;
};

const TableHeadCell = styled.th<CellProps>`
  width: ${({ width }) => width}%;
  text-align: ${({ align = "center" }) => align};
  font-weight: 400;
  color: #7c7987;
  overflow-wrap: break-word;
  border-right: 1px solid #565378;

   &:last-child {
    border-right: none;
  }
`;

const TableCell = styled.td<CellProps>`
  width: ${({ width }) => width}%;
  font-weight: 600;
  text-align: ${({ align = "center" }) => align};
  overflow-wrap: break-word;
  color: ${({ color }) => color};
`;

const Button = styled.button`
  padding: 8px 30px;  
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;
  background: ${({ theme }) => theme.gradients.button};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow};
  font-size: 16px;
  &:hover {
    opacity: 0.8;
  }
`;

export const Promotions: React.FC = () => {
  useGate(PromotionsGate);

  const promotions = useStore($promotions);
  const isLoading = useStore(deletePromotionFx.pending || editPromotionFx.pending);

  const [promotionId, setPromotionId] = useState<string | null>(null);

  const deletePromotion = (id: string) => deletePromotionFx(id);

  const handleClosePopover = useCallback(() => setPromotionId(null), []);
  const handleTogglePopover = useCallback(
    (promotion: Promotion) =>
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        promotion.id && setPromotionId(promotion.id);
      },
    []
  );
  return (
    <>
      <PageLayout
        titleComponent={
          <Typography variant="bold20">
            <Underlined>Advertising company</Underlined>
          </Typography>
        }
        amountComponent={
          <Button onClick={() => createPromotion(null)
          }>ADD NEW+</Button>
        }
      >
        <Table striped >
          <TableHead>
            <TableRow>
              <TableHeadCell width={5}><Burger /></TableHeadCell>
              <TableHeadCell width={10}>Bonus</TableHeadCell>
              <TableHeadCell width={10}>Bonus Type</TableHeadCell>
              <TableHeadCell width={20}>Packs</TableHeadCell>
              <TableHeadCell width={20}>Start Date</TableHeadCell>
              <TableHeadCell width={20}>End Date</TableHeadCell>
              <TableHeadCell width={10}>Status</TableHeadCell>
              <TableHeadCell width={5}>Actions</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <Loader />}
            {promotions.length === 0 && (
              <EmptyTablePlaceholder>
                No promotions available
              </EmptyTablePlaceholder>
            )}
            {promotions.map((promotion: Promotion, index: number) => (
              <TableRow key={promotion.id} clickable>
                <TableCell width={5}>{index + 1}</TableCell>
                <TableCell width={10}>{promotion.bonus}</TableCell>
                <TableCell width={10}>{promotion.bonusType}</TableCell>
                <TableCell width={20}>{getPacksText(promotion.packs)}</TableCell>
                <TableCell width={20}>
                  {formatDateToAustralian(promotion.startDate)}
                </TableCell>
                <TableCell width={20}>
                  {formatDateToAustralian(promotion.endDate)}
                </TableCell>
                <TableCell color={promotion.isActive ? "#05fe86" : "#FDD440"} width={10}>{promotion.isActive ? "Active" : "Inactive"}</TableCell>
                <TableCell width={5}>
                  <Popover
                    isOpen={promotionId === promotion.id}
                    onClose={handleClosePopover}
                    content={
                      <>
                        <PopoverOption
                          onClick={() => {
                            handleClosePopover();
                            promotion && promotion.id && createPromotion(promotion);
                          }
                          }
                        >
                          Edit promotion
                        </PopoverOption>
                        <PopoverOption
                          onClick={() => {
                            handleClosePopover();
                            promotion && promotion.id && deletePromotion(promotion.id);
                          }
                          }
                        >
                          Delete promotion
                        </PopoverOption>
                      </>
                    }
                  >
                    <PromotionOptionsButton
                      onClick={handleTogglePopover(promotion)}
                    >
                      <UserOptionsIcon />
                    </PromotionOptionsButton>
                  </Popover>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CreatePromotionModal />

      </PageLayout>
    </>
  );
};
