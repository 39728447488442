import {
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from "effector";
import { createGate } from "effector-react";

import { api } from "shared/api";
import { interval } from "shared/lib/interval";
import { Race, Region, Statistic } from "shared/types/races";

import {
  getDayInterval,
  getDayIntervalAsObj,
} from "./lib";
import { Day, RaceIdentifier } from "./types";

const RACES_TIMEOUT = 10000;

const startPollPicks = createEvent();
const stopPollPicks = createEvent();
const startPollTodayRaces = createEvent();
const stopPollTodayRaces = createEvent();

const { tick: loadRaces } = interval({
  timeout: RACES_TIMEOUT,
  start: startPollTodayRaces,
  stop: stopPollTodayRaces,
});

export const loadRacesFx = createEffect<{ startUtc: string; endUtc: string }, Region[]>(api.fetchRaces);
export const loadStatisticFx = createEffect<string, Statistic>(
  api.fetchStatistic
);

export const firstLoadRaces = createEvent<{ startUtc: string; endUtc: string }>();

export const setActiveRace = createEvent<RaceIdentifier | null>();

export const resetStatistics = createEvent();
export const resetActiveRace = createEvent();

export const $races = restore(loadRacesFx.doneData, []);
export const $isLoaderVisible = createStore(false);
export const $statistic = restore(loadStatisticFx.doneData, null);
export const $activeRace = restore(setActiveRace, null);

$statistic.reset(resetStatistics);
$activeRace.reset(resetActiveRace);

$isLoaderVisible.on(firstLoadRaces, () => true);
$isLoaderVisible.on(loadRacesFx.finally, () => false);

export const RacesGate = createGate<{ day: Day }>();

const $day = RacesGate.state.map((state) => state.day);

sample({
  clock: loadRaces,
  source: $day,
  fn: getDayIntervalAsObj,
  target: loadRacesFx,
});

guard({
  source: $day.map(getDayInterval),
  filter: RacesGate.status,
  target: loadStatisticFx,
});

guard({
  source: $day.map(getDayIntervalAsObj),
  filter: RacesGate.status,
  target: firstLoadRaces,
});

sample({
  clock: RacesGate.open,
  source: $day.map(getDayInterval),
  target: [loadStatisticFx],
});

forward({
  from: firstLoadRaces,
  to: loadRacesFx,
});

forward({
  from: RacesGate.state,
  to: resetActiveRace,
});

forward({
  from: RacesGate.open,
  to: [startPollPicks, startPollTodayRaces],
});

forward({
  from: RacesGate.close,
  to: [stopPollPicks, stopPollTodayRaces],
});

const getRaceFx = createEffect(api.getCurrentRace);
export const $currentRace = createStore<Race | null>(null);
sample({
  source: $races,
  clock: setActiveRace,
  fn: (races, activeRace) => {
    const currentRace = races
      .find((region) => region.state === activeRace?.region)?.tracks
      .find((track) => track.name === activeRace?.track)?.races
      .find((race) => race.number === activeRace?.race);

    return {
      utc: currentRace?.originalStartTime as string,
      raceNumber: currentRace?.number.toString() as string,
      trackName: activeRace?.track as string,
    };
  },
  target: getRaceFx
});
sample({
  clock: getRaceFx.doneData,
  target: $currentRace,
});
$currentRace.reset(getRaceFx.pending);