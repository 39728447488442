import { createEffect, createEvent, createStore, restore, sample } from "effector";
import { createGate } from "effector-react";

import { api } from "shared/api";
import { Promotion } from "shared/types/promotions";

export const loadPromotionsFx = createEffect<void, Promotion[]>(
  api.getPromotions
);

export const createPromotionFx = createEffect<
  Promotion,
  any,
  any
>();

export const deletePromotionFx = createEffect(api.deletePromotion);

export const editPromotionFx = createEffect(
  api.editPromotion
);

export const PromotionsGate = createGate();

export const $promotions = restore(loadPromotionsFx, []);

export const createPromotion = createEvent<Promotion | null>();
export const cancelPromotionCreation = createEvent();
export const $promotion = createStore<Promotion | null>(null);

sample({
  clock: createPromotion,
  target: $promotion,
});

export const $isCreatePromotionModalVisible = createStore(false);
export const $isSuccessMessageVisible = createStore("");

export const $errors = createStore<ServerFormError[]>([]);

createPromotionFx.use(api.createPromotion);

$promotion
  .on(createPromotion, (_, data) => data)
  .reset(cancelPromotionCreation);

$isSuccessMessageVisible
  .on(createPromotionFx.done, () => "Promotion has successfully been added")
  .on(editPromotionFx.done, () => "Promotion has successfully been edited")
  .reset(cancelPromotionCreation);

$errors
  .on(createPromotionFx.fail, (_, { error }) => error.response?.data.errors || [])
  .reset([createPromotionFx.done, cancelPromotionCreation]);

$isCreatePromotionModalVisible.on(createPromotion, () => true).reset(cancelPromotionCreation);

sample({
  clock: [PromotionsGate.open, createPromotionFx.done, deletePromotionFx.done, editPromotionFx.done],
  target: loadPromotionsFx,
});

export const $creationError = createStore<string | null>(null).
  on(
    createPromotionFx.failData,
    (_, error) => error.response?.data?.message
  )
  .on(
    [createPromotionFx.done, cancelPromotionCreation],
    () => null
  );
  
