import { useEffect } from "react";
import { FieldValues, Path, UseFormSetError } from "react-hook-form";

export function useServerFormErrors<T extends FieldValues>(
  errors: ServerFormError[],
  setError: UseFormSetError<T>
  // fields: (keyof T)[]
) {
  useEffect(() => {
    errors.forEach(({ property, message }) => {
      // if (fields.includes(property as keyof T))
      setError(property as Path<T>, { type: "server", message });
    });
  }, [errors, setError]);
}

export function useServerFormError<T extends FieldValues>(
  error: string,
  property: Path<T>,
  setError: UseFormSetError<T>
) {
  useEffect(() => {
    if (error) {
      setError(property, { type: "server", message: error });
    }
  }, [error, setError, property]);
}