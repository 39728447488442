export const Calendar = ()=><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_359_11625)" filter="url(#filter0_d_359_11625)">
<rect x="5.10715" y="2.31784" width="15.7857" height="14.5716" rx="3.03573" stroke="#52FF82" strokeWidth="1.21429"/>
<path d="M9.35547 0.898193V4.13639" stroke="#52FF82" strokeWidth="1.21429" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.6445 0.898193V4.13639" stroke="#52FF82" strokeWidth="1.21429" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.8555 7.78101L8.14118 7.78101" stroke="#52FF82" strokeWidth="1.21429" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<filter id="filter0_d_359_11625" x="0" y="0" width="26" height="26" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_359_11625"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_359_11625" result="shape"/>
</filter>
<clipPath id="clip0_359_11625">
<rect width="18" height="18" fill="white" transform="translate(4)"/>
</clipPath>
</defs>
</svg>;
