import styled from "styled-components";

import { Input } from "shared/ui/Input";

export const InputModal = styled(Input)<{ error?: boolean }>`
  height: 36px;
  padding: 8px 12px;
  background-color: #272134;
  border-color: ${({ error, theme }) =>
    error ? theme.colors.error : "transparent"};
  border-radius: 8px;
  caret-color:  ${({ theme }) => theme.colors.active};
`;
