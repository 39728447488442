import styled from "styled-components";

import { ErrorWrap } from "shared/ui/FormField";
import { Error } from "shared/ui/FormField";
const FieldWrap = styled.div`
  flex:1;
  position: relative;
`;

type Props = {
  error?: string;
};

export const FormField: React.FC<Props> = ({ error, children }) => {
  return (
    <FieldWrap>
      {children}
      <ErrorWrap>
        <Error>{error}</Error>
      </ErrorWrap>
    </FieldWrap>
  );
};
