import {
  Customer,
  CustomerDetails,
  CustomerPayment,
} from "shared/types/customer";
import { Manager } from "shared/types/manager";
import { PaymentsStatistic } from "shared/types/payment";
import { Promotion } from "shared/types/promotions";
import { Race, Region, Statistic } from "shared/types/races";
import { CustomersAndManagersTotals } from "shared/types/totals";

import { http } from "./instance";
import { LoginForm, PagingParams, ResetPasswordConfirm, ResetPasswordNewPassword, ResetPasswordRequest, User } from "./types";

export const login = (data: LoginForm) => http.post("/api/admin/login", data, { timeout: 20000 });

export const logout = () => http.get("/api/admin/logout");

export const loadAccount = () =>
  http.get<{ data: Manager }>("/api/managers/me").then(({ data }) => data.data);

export const fetchUsers = (params: Partial<PagingParams>) =>
  http
    .get<Pageable<Manager>>("/api/managers", { params })
    .then(({ data }) => data);

export const fetchCustomers = (params: Partial<PagingParams> | void) =>
  http
    .get<Pageable<Customer>>("/api/customers", { params })
    .then(({ data }) => data);

export const fetchCustomerDetails = (id: string) =>
  http
    .get<{ data: CustomerDetails }>(`/api/customers/${id}`)
    .then(({ data }) => data.data);

export const fetchCustomerPayments = (id: string) =>
  http
    .get<{ data: CustomerPayment[] }>(`/api/customers/${id}/payments`)
    .then(({ data }) => data.data);

export const fetchPayments = (params: Partial<PagingParams>) =>
  http
    .get<Pageable<Customer>>("/api/customers/payments", { params })
    .then(({ data }) => data);

export const fetchPaymentsStatistic = () =>
  http
    .get<Show<PaymentsStatistic>>("/api/payments/statistic")
    .then(({ data }) => data.data);

export const assignUserAsAdmin = (ids: string[]) =>
  http.post("/api/managers/assign-as-admin", { ids });

export const createUser = (data: User) =>
  http.post("/api/managers/create", data);

export const deleteUser = (ids: string[]) =>
  http.post("/api/managers/delete", { ids });

export const deleteCustomer = (customerId: string) =>
  http.delete(`/api/managers/delete-customer/${customerId}`);

export const removeUserFromAdmins = (ids: string[]) =>
  http.post("/api/managers/remove-from-admins", { ids });

export const fetchRaces = (params: { startUtc: string; endUtc: string }) =>
  http
    .get<Show<Region[]>>(
      "/api/races/tracks-list", {
      params,
    }
    )
    .then(({ data }) => data.data);

export type getRaceDto = {
  utc: string;
  raceNumber: string;
  trackName: string;
}

export const getCurrentRace = (params: getRaceDto) =>
  http
    .get<Show<Race>>("api/races/race", { params })
    .then(({ data }) => data.data);

export const fetchCustomersAndManagersTotals = () =>
  http
    .get<Show<CustomersAndManagersTotals>>("/api/managers/totals")
    .then(({ data }) => data.data);

export const fetchStatistic = (range: string) =>
  http
    .get<Show<Statistic>>("/api/races/statistic", {
      params: { "filter[utc]": range },
    })
    .then(({ data }) => data.data);

export const activateCustomer = (id: string) =>
  http.post(`/api/customers/${id}/activate`);

export const deactivateCustomer = (id: string) =>
  http.post(`/api/customers/${id}/deactivate`);

export const giveCustomerCredits = (data: {
  customerId: string;
  amount: number | string;
}) => http.post("/api/managers/free-credits", data);

export const resetPasswordRequest = (data: ResetPasswordRequest) =>
  http.post("/api/managers/request-reset-password", data);

export const resetPasswordConfirm = ({ token }: ResetPasswordConfirm) =>
  http.post("/api/managers/reset-password/confirm/" + token);

export const resetPasswordNewPassword = ({
  token,
  newPassword,
}: ResetPasswordNewPassword) =>
  http.post("/api/managers/reset-password/" + token, { newPassword });

export const getPromotions = () =>
  http
    .get<Pageable<Promotion>>("/api/campaign/all")
    .then(({ data }) => data.data);

export const createPromotion = (data: Promotion) => http.post("/api/campaign/create",
  data);


export const editPromotion = (data: Promotion) => http.post(`/api/campaign/edit/${data.id}`,
  data);


export const deletePromotion = (id: string) => http.delete(`/api/campaign/delete/${id}`);
