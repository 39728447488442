import { Redirect, Route, Router, Switch } from "react-router-dom";
import { useGate, useStore } from "effector-react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { customTheme } from "theme";

import { history } from "router/history";

import { $authStatus, AuthStatus } from "features/auth/model";
import { CustomersPage } from "pages/Customers";
import { ForgotPasswordPage } from "pages/ForgotPassword";
import { NewPasswordPage } from "pages/ForgotPassword/NewPasswordPage";
import { ResetCodePage } from "pages/ForgotPassword/ResetCode";
import { LoginPage } from "pages/Login";
import { PaymentsPage } from "pages/Payments";
import { Promotions } from "pages/Promotions";
import { RacesPage } from "pages/Races";
import { UsersPage } from "pages/Users";

import { AppGate } from "../model";

import { Layout } from "./Layout";

import "normalize.css";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 30px;
  }
  ul {
    padding: 0;
    margin: 0;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

`;

export function App() {
  useGate(AppGate);
  const status = useStore($authStatus);

  return (
    <ThemeProvider theme={customTheme}>
      <GlobalStyle />
      {status !== AuthStatus.UNKNOWN && (
        <Router history={history}>
          <Switch>
            <Route path="/login">
              {status === AuthStatus.AUTHORIZED ? (
                <Redirect to="/races" />
              ) : (
                <LoginPage />
              )}
            </Route>
            <Route path="/forgot">
              <ForgotPasswordPage />
            </Route>
            <Route path="/resetCode">
              <ResetCodePage />
            </Route>
            <Route path="/newPassword">
              <NewPasswordPage />
            </Route>

            <Route exact path="/">
              {status === AuthStatus.AUTHORIZED ? (
                <Redirect to="/races" />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>

            {status === AuthStatus.AUTHORIZED ? (
              <Layout>
                <Route path="/races">
                  <RacesPage />
                </Route>
                <Route path="/customers">
                  <CustomersPage />
                </Route>
                <Route path="/payments">
                  <PaymentsPage />
                </Route>
                <Route path="/users">
                  <UsersPage />
                </Route>
                <Route path="/promotions">
                  <Promotions />
                </Route>
              </Layout>
            ) : (
              <Redirect to="/login" />
            )}
          </Switch>
        </Router>
      )}
    </ThemeProvider>
  );
}
