import styled from "styled-components";

import icon from "../images/chevron.svg";

export const Select = styled.select<{ error?: boolean }>`
  padding: 8px 12px;
  height: 36px;
  width: 100%;
  font-size: 14px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.input};
  background-color: #272134;
  border-width: 0px;
  border-radius: 8px;
  box-sizing: border-box;
  appearance: none; /* Removes default arrow for custom styling */
  background-image: url(${icon});
  background-repeat: no-repeat;
  background-position: right 16px bottom 8px;

  &:focus {
    color: ${({ theme }) => theme.colors.text};
    border-color: ${({ theme }) => theme.colors.text};
    outline: none;
  }

  & option {
    color: ${({ theme }) => theme.colors.text};
  }

  /* Autofill styles similar to input */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.input};
    transition: background-color 50000s ease-in-out 0s;
  }
`;